import { Controller } from "@hotwired/stimulus"
import { enter, leave, toggle } from "el-transition"

export default class extends Controller {
  static targets = ["backdrop", "dialog"]

  static values = {
    open: {
      type: Boolean,
      default: false
    }
  }

  initialize() {
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  connect() {
    if (this.openValue) {
      this.open()
    }

    document.addEventListener("turbo:before-render", this.close)
  }

  disconnect() {
    document.removeEventListener("turbo:before-render", this.close)
  }

  open() {
    this.openValue = true
    enter(this.backdropTarget)
    enter(this.dialogTarget)
  }

  close() {
    this.openValue = false
    leave(this.backdropTarget)
    leave(this.dialogTarget)
  }

  backdropClose(event) {
    if (event.target === this.backdropTarget) {
      this.close()
    }
  }

  toggle() {
    this.openValue = !!!this.openValue
    toggle(this.backdropTarget)
    toggle(this.dialogTarget)
  }
}
