import { Controller } from "@hotwired/stimulus"
import { leave, toggle } from "el-transition"

export default class extends Controller {
  static targets = ["dropdown"]
  static classes = ["open"]

  toggle() {
    toggle(this.dropdownTarget)
    if (this.openClasses.length > 0) {
      this.element.classList.toggle(...this.openClasses)
    }
  }

  hide(event) {
    if (this.element.contains(event.target) || this.dropdownTarget.classList.contains("hidden")) {
      return
    }

    leave(this.dropdownTarget)

    if (this.openClasses.length > 0) {
      this.element.classList.remove(...this.openClasses)
    }
  }
}
