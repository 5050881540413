// Turbo
import "@hotwired/turbo-rails"

// Stimulus
import { Application } from "@hotwired/stimulus"

// General controllers
import ScrollToController from "./controllers/scroll_to_controller"
// LUX controllers
import BannerController from "./lux/controllers/banner_controller"
import DialogController from "./lux/controllers/dialog_controller"
import DialogToggleController from "./lux/controllers/dialog_toggle_controller"
import DropdownController from "./lux/controllers/dropdown_controller"
import FormController from "./lux/controllers/form_controller"
import SnackbarController from "./lux/controllers/snackbar_controller"

const app = Application.start()

const controllers = [
  ["banner", BannerController],
  ["dialog", DialogController],
  ["dialog-toggle", DialogToggleController],
  ["dropdown", DropdownController],
  ["form", FormController],
  ["scroll-to", ScrollToController],
  ["snackbar", SnackbarController],
]

controllers.forEach((controller) => {
  app.register(...controller)
})
